<template>
  <div class="c-results u-mb">
    <FHBreadcrumbs
      class="c-results__breadcrumbs u-px u-py"
      v-if="crumbs"
      :crumbs="crumbs"
    />

    <SearchCounter
      v-if="term"
      class="c-results__counter u-px u-py"
      :term="term"
      :count="count"
    >
      {{ $t('SEARCH_COUNT_TEXT') }}
    </SearchCounter>

    <div class="c-results__wrapper u-px">
      <SearchResults
        v-if="term"
        class="c-results__results"
        :term="term"
        showCats
        :contentTypes="contentTypes"
        :searchBaseURL="searchBaseURL"
        :searchQuery="query"
        :indexName="indexName"
        :units="units"
        showAllTab
        @on-result-count="onResultCount"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import FHBreadcrumbs from '@forlagshuset/v-breadcrumbs'
import SearchCounter from '@forlagshuset/v-search-counter'
import SearchResults from '@forlagshuset/v-search-results/FHSearchResults'
import searchQuery from '@forlagshuset/v-search-results/services/es'

export default {
  name: 'Search',

  components: {
    SearchCounter,
    SearchResults,
    FHBreadcrumbs,
  },

  setup(props, ctx) {
    const isTeacher = computed(
      () => ctx.root.$store.getters['appConfig/isTeacher'],
    )
    const crumbs = ref([])
    const phrase = computed(
      () => ctx.root.$router.history.current.params.phrase,
    )
    const term = computed(() => ctx.root.$store.getters['search/getPhrase'])
    const count = ref(0)
    const contentTypes = computed(() =>
      isTeacher.value
        ? ['lesson', 'teacher_lesson', 'audio', 'activity', 'article']
        : ['audio', 'activity', 'article'],
    )
    const units = ref([])
    const searchBaseURL = computed(() => process.env.VUE_APP_ES_URL)
    const indexName = ref('strapi_en_gb')
    const query = computed(() => searchQuery(term.value, contentTypes.value))

    onMounted(async () => {
      await ctx.root.$store.dispatch(
        'content/setCourseUnits',
        process.env.VUE_APP_APPSLUG,
      )
      units.value = ctx.root.$store.getters['content/getCourseUnits']

      if (phrase.value) {
        ctx.root.$store.dispatch('search/setPhrase', phrase.value)
      }

      crumbs.value.push({
        type: 'results',
        name: 'Results',
        slug: '',
      })
    })

    const onResultCount = e => {
      count.value = e
    }

    return {
      crumbs,
      phrase,
      term,
      count,
      onResultCount,
      contentTypes,
      searchBaseURL,
      indexName,
      units,
      query,
      isTeacher,
    }
  },
}
</script>

<style lang="scss">
.c-results {
  min-height: calc(100vh - 12.375rem);

  .c-results__breadcrumbs {
    @include bp-down($medium) {
      display: none;
    }
    .c-breadcrumbs__home {
      text-decoration: none;
      color: $color-black;
      .c-breadcrumbs__home {
        fill: none;
        width: 0;
      }
      &:before {
        content: 'Home';
        vertical-align: super;
      }
    }
    .c-breadcrumbs__link {
      color: $color-black;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .c-results__counter {
    display: block;
    .c-search-counter__term {
      &:before,
      &:after {
        content: '"';
      }
    }
  }

  .c-results__wrapper {
    display: flex;
    max-width: 1366px;
  }
  .c-results__results {
    width: 100%;

    .c-tab__panel {
      display: flex;
      flex-wrap: wrap;
      padding: 2rem 0;

      &#text {
        .c-search-tile {
          .c-search-tile__icon--text {
            display: block;
          }
        }
      }
      &#video {
        .c-search-tile {
          .c-search-tile__icon--video {
            display: block;
          }
        }
      }
      &#audiobook {
        .c-search-tile {
          .c-search-tile__icon--audio {
            display: block;
          }
        }
      }
      &#lesson-plans {
        .c-search-tile {
          .c-search-tile__icon--lp {
            display: block;
          }
        }
      }
    }
  }

  .c-results__empty {
    font-size: 1rem;
    font-weight: 700;
    margin: 3rem 1rem;
  }

  .c-results__tabs {
    width: 70%;

    border-left: 1px solid $color-black;
    padding-left: 2rem;
  }

  .c-tabs__list-wrapper {
    background: none;
    border: none;
  }

  .c-tabs__btn {
    width: 100%;
    margin: 0;
    background: none;
    border-radius: 0;
    border: 1px solid $color-divider !important;
    font-size: 1rem;
    text-align: left;
    padding: 0.75rem 1rem;

    &--active {
      background: $color-black !important;
      color: $color-white !important;
    }

    &.c-button--disabled {
      border-color: $color-grey !important;
    }

    &::before {
      display: none;
    }
  }

  .c-tabs {
    width: 100%;
    padding: 0 !important;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .c-tabs__panels-wrapper {
    background: none !important;

    .c-tab__panel {
      background: none !important;
      flex-direction: column;
      .c-search-result {
        color: $search-results-color;
        width: 100%;
        max-width: 342px;
        margin: 0 1.25rem;
        padding: 1.25rem 0.25rem;
        border-bottom: 1px solid $color-medium-grey;
        @include bp-down($small) {
          margin: 0 2px;
          max-width: initial;
          width: initial;
        }
        [class^='c-search-result--'] {
          display: flex;
          flex-direction: column;
        }

        .c-search-result__title {
          line-height: 1.25rem;
          padding-left: 1.75rem;
          background-size: 1.25rem 1.25rem;
          p {
            font-size: 0.875rem;
            line-height: 1.35rem;
          }
        }
        .c-search-result__unit {
          margin: 0.25rem 0;
          order: 1;
        }

        .c-search-result__desc {
          padding-top: 0;
        }
      }
    }
  }

  .c-tabs__item {
    width: auto;
    padding: 0;

    &:before {
      display: none;
    }
  }
}
</style>
